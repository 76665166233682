import React, { useEffect, useMemo, useState } from 'react'
import { TimeExpired } from '../../assets/icon-svgs'
import useCountDown from '../../hooks/useCountDown'
import styles from './styles.module.css'

const CountDown = ({ expiryDate = Date.now(), getExpiryDate = () => {} }) => {
  const [targetDate] = useState(new Date(expiryDate).getTime())
  return (
    <div>
      <CountDownTimer targetDate={targetDate} getExpiryDate={getExpiryDate} />
    </div>
  )
}

const ExpiredNotice = () => {
  return (
    <div className={styles.expiredNotice}>
      <TimeExpired />
    </div>
  )
}

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className={styles.timeContainer}>
      <DateTimeDisplay className={styles.days} value={days} type={'Days'} isDanger={days <= 3} />
      <DateTimeDisplay className={styles.hours} value={hours} type={'Hours'} isDanger={false} />
      <DateTimeDisplay className={styles.minutes} value={minutes} type={'Minutes'} isDanger={false} />
      <DateTimeDisplay className={styles.seconds} value={seconds} type={'Seconds'} isDanger={false} />
    </div>
  )
}

const DateTimeDisplay = ({ value, type, isDanger, className }) => {
  return (
    <div className={`${isDanger ? 'countdown danger' : 'countdown'} ${className}`}>
      <p className={styles.numbers}>{value}</p>
      <span className={styles.type}>{type}</span>
    </div>
  )
}

const CountDownTimer = ({ targetDate, getExpiryDate }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate)
  const countdownHasExpired = useMemo(() => days + hours + minutes + seconds <= 0, [days, hours, minutes, seconds])

  useEffect(() => {
    getExpiryDate(countdownHasExpired)
  }, [getExpiryDate, countdownHasExpired])

  if (countdownHasExpired) {
    return <ExpiredNotice />
  } else {
    return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
  }
}

export { CountDown }
