import React from 'react'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { ApplicationPage, LandingPage, TaskAndPersonalitySubmissionPage, TaskSubmissionPage } from '../pages'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" caseSensitive={true} element={<LandingPage />} />
        <Route path="/application_form" caseSensitive={true} element={<ApplicationPage />} />
        <Route path="/task_submission" caseSensitive={true} element={<TaskSubmissionPage />} />
        <Route
          path="/personality_final_submission"
          caseSensitive={true}
          element={<TaskAndPersonalitySubmissionPage />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export { Router }
