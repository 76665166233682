import React from 'react'
import { useNavigate } from 'react-router'
import { ToastContainer } from 'react-toastify'

import bezaoLogo from '../../assets/Bezao-logo.png'
import { ApplySVG } from '../../assets/icon-svgs'
import { Checkbox } from '../../components/Checkbox'
import { TextInput } from '../../components/Input'
import { SubmitSuccessUI } from '../../components/SubmitSuccessUI'
import { TextArea } from '../../components/Textarea'
import { FORM_INPUT_FIELDS } from '../../data'
import useApplicationForm from '../../hooks/useApplicationForm'
import styles from './styles.module.css'

const ApplicationPage = () => {
  const navigate = useNavigate()

  // Custom hook for stateful logic
  const {
    CHECKBOXES_LIST,
    RADIOINPUT_LIST,
    reactHookFormVariables,
    hideCheckBoxesError,
    formValues,
    appSubmittedSuccessfully,
    taskSubmissionStatus,
    handleApplicationSubmission,
  } = useApplicationForm()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = reactHookFormVariables

  return (
    <div className={styles.container}>
      <nav className={styles.nav}>
        <img src={bezaoLogo} alt="" onClick={() => navigate('/')} />
      </nav>
      <section className={`${styles.registrationContainer}`}>
        {appSubmittedSuccessfully ? (
          <SubmitSuccessUI message="Application Submitted Successfully! Please, check your email for the next instructions." />
        ) : (
          <form onSubmit={handleSubmit(handleApplicationSubmission)}>
            <section>
              <div className={styles.header}>
                <ApplySVG />
                <h4>Fill in the information below to get started.</h4>
              </div>
              <hr />
            </section>
            <section className={`${styles.form_section}`}>
              <fieldset>
                <legend>Personal Information</legend>
                <div className={styles.field_group}>
                  {Object.keys(FORM_INPUT_FIELDS.personal_info_1).map((input, idx) => {
                    const inputProps = FORM_INPUT_FIELDS.personal_info_1[input]
                    return (
                      <TextInput
                        key={input}
                        {...register(input)}
                        error={errors?.[input]?.message}
                        {...inputProps}
                        className={styles.textInput}
                      />
                    )
                  })}
                </div>

                <div className={styles.field_group}>
                  {Object.keys(FORM_INPUT_FIELDS.personal_info_2).map((input, idx) => {
                    const inputProps = FORM_INPUT_FIELDS.personal_info_2[input]
                    return (
                      <TextInput
                        key={input}
                        {...register(input)}
                        error={errors?.[input]?.message}
                        {...inputProps}
                        className={styles.textInput}
                      />
                    )
                  })}
                </div>

                <div className={styles.field_group}>
                  {Object.keys(FORM_INPUT_FIELDS.personal_info_3).map((input, idx) => {
                    const inputProps = FORM_INPUT_FIELDS.personal_info_3[input]
                    return (
                      <TextInput
                        key={input}
                        {...register(input)}
                        error={errors?.[input]?.message}
                        {...inputProps}
                        className={styles.textInput}
                      />
                    )
                  })}
                </div>

                <div className={styles.field_group}>
                  {Object.keys(FORM_INPUT_FIELDS.personal_info_4).map((input, idx) => {
                    const inputProps = FORM_INPUT_FIELDS.personal_info_4[input]
                    return (
                      <TextInput
                        key={input}
                        {...register(input)}
                        error={errors?.[input]?.message}
                        {...inputProps}
                        className={styles.textInput}
                      />
                    )
                  })}
                </div>

                <div className={`${styles.field_group} ${styles.column}`}>
                  <label className={styles.field_group_header}>
                    <span className={styles.asterisk}>* </span>Gender
                  </label>

                  <div className={styles['gender_form']}>
                    {RADIOINPUT_LIST.gender.map((input, idx) => (
                      <label key={`${input}-gender`}>
                        <input
                          type="radio"
                          name="gender"
                          value={input}
                          {...register('gender')}
                          id={`${input}-gender`}
                        />
                        <span>{input}</span>
                      </label>
                    ))}
                  </div>
                  {errors?.['gender']?.message && <p className={styles.errorMsg}>{errors?.['gender']?.message}</p>}
                </div>

                <div className={`${styles.field_group} ${styles.column}`}>
                  <label className={styles.field_group_header}>
                    <span className={styles.asterisk}>* </span>What describes you best?
                  </label>

                  <div className={styles['gender_form']}>
                    {RADIOINPUT_LIST.what_describes_you_best.map((input, idx) => (
                      <label key={`${input}-what_describes_you_best`}>
                        <input
                          type="radio"
                          name="what_describes_you_best"
                          value={input}
                          {...register('what_describes_you_best')}
                          id={`${input}-what_describes_you_best`}
                        />
                        <span>{input}</span>
                      </label>
                    ))}
                  </div>
                  {errors?.['what_describes_you_best']?.message && (
                    <p className={styles.errorMsg}>{errors?.['what_describes_you_best']?.message}</p>
                  )}
                  {formValues.what_describes_you_best === 'Others' && (
                    <TextInput
                      id="what_describes_you_best_text"
                      name="what_describes_you_best_text"
                      style={{ marginTop: '20px' }}
                      error={errors?.['what_describes_you_best_text']?.message}
                      className={styles.textInput}
                      placeholder="Tell us... "
                      {...register('what_describes_you_best_text')}
                    />
                  )}
                </div>
              </fieldset>
            </section>
            <hr />

            <section className={`${styles.form_section}`}>
              <fieldset>
                <legend>Programming Profile</legend>

                <div className={`${styles.field_group} ${styles.column}`}>
                  <label className={styles.field_group_header}>
                    <span className={styles.asterisk}>* </span>Rate your programming experience
                  </label>

                  <div className={styles['gender_form']}>
                    {RADIOINPUT_LIST.skill_rating_description.map((input, idx) => (
                      <label key={`${input}-skill_rating_description`}>
                        <input
                          type="radio"
                          name="skill_rating_description"
                          value={input}
                          {...register('skill_rating_description')}
                          id={`${input}-skill_rating_description`}
                        />
                        <span>{input}</span>
                      </label>
                    ))}
                  </div>
                  {errors?.['skill_rating_description']?.message && (
                    <p className={styles.errorMsg}>{errors?.['skill_rating_description']?.message}</p>
                  )}

                  {formValues.skill_rating_description === 'Others' && (
                    <TextInput
                      id="skill_rating_description_others"
                      name="skill_rating_description_others"
                      style={{ marginTop: '20px' }}
                      error={errors?.['skill_rating_description_others']?.message}
                      className={styles.textInput}
                      placeholder="Tell us... "
                      {...register('skill_rating_description_others')}
                    />
                  )}
                </div>
              </fieldset>
            </section>
            <hr />
            <section className={`${styles.form_section}`}>
              <fieldset>
                <legend>Track</legend>

                <div className={`${styles.field_group} ${styles.column}`}>
                  <label className={styles.field_group_header}>
                    <span className={styles.asterisk}>* </span>What is your programming track?
                  </label>

                  <div className={styles['gender_form']}>
                    {RADIOINPUT_LIST.programme_stack.map((input, idx) => (
                      <label key={`${input}-programme_stack`}>
                        <input
                          type="radio"
                          name="programme_stack"
                          value={input}
                          {...register('programme_stack')}
                          id={`${input}-programme_stack`}
                        />
                        <span>{input}</span>
                      </label>
                    ))}
                  </div>
                  {errors?.['programme_stack']?.message && (
                    <p className={styles.errorMsg}>{errors?.['programme_stack']?.message}</p>
                  )}
                </div>
              </fieldset>
            </section>
            <hr />

            <section className={`${styles.form_section}`}>
              <fieldset>
                <legend>Languages and Frameworks</legend>{' '}
                <div className={`${styles.field_group} ${styles.column}`}>
                  <label className={styles.field_group_header}>
                    <span className={styles.asterisk}>* </span>Which of the following languages have you used?
                  </label>
                  <div className={styles.checkbox_wrapper}>
                    {CHECKBOXES_LIST.code_language_used.map((checkboxItem, idx) => (
                      <Checkbox
                        key={checkboxItem}
                        name={`code_language_used[${idx}]`}
                        id={checkboxItem}
                        value={checkboxItem}
                        label={checkboxItem}
                        {...register('code_language_used')}
                      />
                    ))}
                  </div>
                </div>
                {!hideCheckBoxesError.code_language_used && (
                  <p className={styles.errorMsg}>Please select accordingly.</p>
                )}
                <div className={`${styles.field_group} ${styles.column}`}>
                  <label className={styles.field_group_header}>
                    <span className={styles.asterisk}>* </span>Which of the following frameworks have you used?
                  </label>
                  <div className={styles.checkbox_wrapper}>
                    {CHECKBOXES_LIST.language_framework.map((checkboxItem, idx) => (
                      <Checkbox
                        key={checkboxItem}
                        name={`language_framework[${idx}]`}
                        id={checkboxItem}
                        value={checkboxItem}
                        label={checkboxItem}
                        {...register('language_framework')}
                      />
                    ))}

                    {formValues?.language_framework && formValues?.language_framework?.includes('Others') && (
                      <TextInput
                        id="other_languages_text"
                        name="other_languages_text"
                        style={{ marginTop: '20px' }}
                        error={errors?.['other_languages_text']?.message}
                        className={styles.textInput}
                        placeholder="Enter a comma seperated list (ex. Elixir, Rust)"
                        {...register('other_languages_text')}
                      />
                    )}
                  </div>
                </div>
              </fieldset>
            </section>
            <hr />
            <section className={`${styles.form_section}`}>
              <fieldset>
                <legend>Non coding life</legend>
                <div className={`${styles.field_group} ${styles.column}`}>
                  {Object.keys(FORM_INPUT_FIELDS.non_coding_life).map((input, idx) => {
                    const inputProps = FORM_INPUT_FIELDS.non_coding_life[input]
                    const styles = idx === 1 ? { marginTop: '20px' } : {}
                    return (
                      <TextArea
                        key={input}
                        {...inputProps}
                        {...register(input)}
                        containerStyles={{ ...styles }}
                        error={errors?.[input]?.message}
                        className={styles.textInput}
                      />
                    )
                  })}

                  <TextInput
                    name="cv_upload"
                    id="cv_upload"
                    label="Upload CV"
                    type="file"
                    {...register('cv_upload')}
                    className={styles.textInput}
                  />
                  {errors?.['cv_upload']?.message && <p className={styles.errorMsg}>Please provide your CV.</p>}
                </div>
              </fieldset>
            </section>
            <hr />
            <section className={`${styles.form_section}`}>
              <fieldset>
                <legend>Health Status</legend>
                <div className={`${styles.field_group} ${styles.column}`}>
                  <label className={styles.field_group_header}>
                    <span className={styles.asterisk}>* </span>Do you have any preexisting medical condition?
                  </label>

                  <div className={styles['gender_form']}>
                    {RADIOINPUT_LIST.medical_condition.map((input, idx) => (
                      <label key={`${input}-medical_condition`}>
                        <input
                          type="radio"
                          name="medical_condition"
                          value={input}
                          {...register('medical_condition')}
                          id={`${input}-medical_condition`}
                        />
                        <span>{input}</span>
                      </label>
                    ))}
                  </div>
                  {errors?.['medical_condition']?.message && (
                    <p className={styles.errorMsg}>{errors?.['medical_condition']?.message}</p>
                  )}
                </div>

                {formValues.medical_condition === 'Yes' && (
                  <TextArea
                    {...register('medical_condition_text')}
                    error={errors?.['medical_condition_text']?.message}
                    rows="4"
                    id="medical_condition_text"
                    name="medical_condition_text"
                    label="If yes, please state it below."
                    className={styles.textInput}
                  />
                )}
              </fieldset>
            </section>
            <hr />
            <button
              disabled={taskSubmissionStatus === 'pending'}
              type="submit"
              className={`bzo-hero-button blue-variant ${taskSubmissionStatus ? styles.loading : ''} ${styles.button} `}
            >
              Submit {taskSubmissionStatus === 'pending' && <span className="loader"></span>}
            </button>
          </form>
        )}
      </section>
      <div className={styles.submitbg}></div>
      <ToastContainer position="bottom-right" />
    </div>
  )
}

export { ApplicationPage }
