import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import bezaoLogo from '../../assets/Bezao-logo.png'
import { hamburgerIcon } from '../../assets/icon-svgs'

import './landingNav.css'

const LandingNav = () => {
  const navigate = useNavigate()
  const [navigationButtonsDisplay, setNavigationButtionsDisplay] = useState(false)
  const [showHamburger, setShowHamburger] = useState('')

  const showNavigationButtonsDisplay = () => {
    const currentView = navigationButtonsDisplay ? false : true
    currentView ? setShowHamburger('showHamburger') : setShowHamburger('')

    setNavigationButtionsDisplay(currentView)
  }
  return (
    <nav className="bzo-landing-nav" id="landing-nav">
      <div className="bzo-nav-link-wrapper">
        <img src={bezaoLogo} alt="" />

        <div className="bzo-nav-links">
          <ul className={`bzo-nav-links-container ${showHamburger}`}>
            {navigationButtonsDisplay && (
              <div
                className={`bzo-landing-nav-bar--hamburger`}
                onClick={showNavigationButtonsDisplay}
              >
                {hamburgerIcon(true)}
              </div>
            )}
            <li className="nav-link-item">
              <a href="#about">About</a>
            </li>
            <li className="nav-link-item">
              <a href="#how-it-works">How It works</a>
            </li>
            <li className="nav-link-item">
              <a href="#learning">Learning</a>
            </li>
            <li className="nav-link-item">
              <a href="#requirements">Requirements</a>
            </li>
            <li className="nav-link-item">
              <a href="#faqs">FAQs</a>
            </li>           
          </ul>
          
          {/* <button
            disabled
            className="bzo-nav-button  hide-button"
            onClick={() => navigate("/application_form")}
          >
            Register
          </button> */}
          <div
            className="bzo-landing-nav-bar--hamburger"
            onClick={showNavigationButtonsDisplay}
          >
            {hamburgerIcon(navigationButtonsDisplay)}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default LandingNav
