import React from 'react'
import styles from './styles.module.css'

const FrontendTask = () => {
  return (
    <React.Fragment>
      <h4>Instructions</h4>
      <ol className={styles.list}>
        <li className={styles.item}>
          Attempt <span className={styles.emphasis}>Parts One and Two </span>
        </li>
        <li className={styles.item}>
          All solutions should be in <span className={styles.emphasis}>single repository</span>.
        </li>
        <li className={styles.item}>
          However, you must{' '}
          <span className={styles.emphasis}>host your solution to Part One in any hosting service</span>.
        </li>
        <li className={styles.item}>
          <span className={styles.emphasis}> Create a folder in the same repository</span> which the first task was
          hosted on. <span className={styles.emphasis}>It should have the name "Part_Two"</span>. This folder should
          contain your answer to the part two task.
        </li>
        <li className={styles.item}>
          Once your have completed the tasks,{' '}
          <span className={styles.emphasis}>
            submit a link to the repository and the application link for Part One's solution.
          </span>
        </li>
      </ol>
      <hr />
      <h4>Frontend Task Part One</h4>
      <ol className={styles.list}>
        <li className={styles.item}>The goal is simple. Replicate the design.</li>
        <li className={styles.item}>
          Two Figma files are provided for you below, you are expected to select and replicate one of the designs using
          any frontend technologies you are comfortable with.
        </li>
        <li className={styles.item}>
          Host your design on any hosting service.{' '}
          <span className={styles.emphasis}>Preferably on Netlify or Vercel.</span>
        </li>
        <li className={styles.item}>
          Submit the link to the repository (which also contains the solution to task two below) and application link.{' '}
          <span className={styles.emphasis}>Preferably on Netlify or Vercel.</span>
        </li>

        <hr />
        {/* <span className={`${styles.note} ${styles.info}`}>IMPORTANT</span> */}
        <div className={styles.link_container}>
          <span>Link to first Figma file:</span>
          <a
            href="https://www.figma.com/file/MyBPnlNYrOMTJQ0l0WPK69/Bezao---Tasks?node-id=0%3A1"
            className="underline"
            rel="noreferrer"
            target="_blank"
          >
            Click Here
          </a>
        </div>
        <div className={styles.link_container}>
          <span>Link to second Figma file:</span>
          <a
            href="https://www.figma.com/proto/MyBPnlNYrOMTJQ0l0WPK69/Bezao---Tasks?page-id=2%3A3258&node-id=2%3A3440&viewport=668%2C473%2C0.2&scaling=min-zoom&starting-point-node-id=2%3A3261"
            className="underline"
            rel="noreferrer"
            target="_blank"
          >
            Prototype Link
          </a>
          <a
            href="https://www.figma.com/file/MyBPnlNYrOMTJQ0l0WPK69/Bezao---Tasks?node-id=2%3A3258"
            className="underline"
            rel="noreferrer"
            target="_blank"
          >
            Design file Link
          </a>
        </div>
      </ol>
      <hr />
      <h4>Frontend Task Part Two</h4>
      <ol className={styles.list}>
        <li className={styles.item}>Build a restaurant menu system collecting inspiration from the pages above.</li>
        <li className={styles.item}>
          On the menu system, an admin should be able to <span className={styles.emphasis}>ADD</span> a food item and
          its price to the menu.
        </li>
        <li className={styles.item}>
          <span className={styles.emphasis}>REMOVE</span> a food item from the menu.
        </li>
        <li className={styles.item}>
          <span className={styles.emphasis}>DISPLAY</span> all food items in the menu.
        </li>
        <li className={styles.item}>
          Solve the task and submit the link to the repository (which also contains the solution to task one above) and
          application link.
        </li>
        <hr />

        <div className={styles.link_container}>
          <span>
            <span className={styles.note}>NOTE</span> All solutions should be in single repo. Please do submit the link
            to the repository below. You can optionally submit a live link to the page you built.
          </span>
        </div>
      </ol>
      <hr />
    </React.Fragment>
  )
}

export { FrontendTask }
