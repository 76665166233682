import React from 'react'
import { useNavigate } from 'react-router'
import { ApplicationSubmitted } from '../../assets/icon-svgs'
import styles from './styles.module.css'

const SubmitSuccessUI = ({
  message = `Your entry has been recorded. Expect a mail from us with an update. We are glad that you have come
this far! 😇`,
}) => {
  const navigate = useNavigate()
  return (
    <div className={styles.submitted}>
      <ApplicationSubmitted />
      <h2>{message}</h2>
      <button
        type="submit"
        className={`bzo-hero-button blue-variant ${styles.button} `}
        onClick={() => navigate('/', { replace: true })}
      >
        Go home
      </button>
    </div>
  )
}

export { SubmitSuccessUI }
