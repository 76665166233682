import { useEffect, useMemo, useState } from 'react'
import { getRequest } from '../services/httpService'

const useFetchUser = ({ userEmail = '', formSubmissionIntent = '' }) => {
  const [userData, setUserData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [noRecordFound, setNoRecordFound] = useState(false)
  const [restrictedAccess, setRestrictedAccess] = useState(false)
  const [failedRequest, setFailedRequest] = useState(false)
  const [stage, setStage] = useState('stage_one')
  const [submissionExpired, setSubmissionExpired] = useState(false)
  const [hasSubmittedAlready, setHasSubmittedAlready] = useState(false)

  useEffect(() => {
    async function fetchUserData() {
      setIsLoading(true)

      try {
        const userData = await getRequest({ url: `/get-registered-applicant/timer?email=${userEmail}` })
        if (userData.data) {
          // All application stages
          const stageOfApplication = userData?.data?.data?.stage_of_application
          const isStageThree = stageOfApplication === 'stage_three'
          const isStageOne = stageOfApplication === 'stage_one'
          const isStageTwo = stageOfApplication === 'stage_two'

          setUserData(userData.data.data)

          // Shows restriction UI fallback if candidate is ineleigible to view page
          setRestrictedAccess(() => !isStageThree && formSubmissionIntent !== 'stage_one_task_only')
          setHasSubmittedAlready(() =>
            isStageThree && formSubmissionIntent !== 'stage_one_task_only'
              ? userData?.data?.data?.stage_submitteed_for === 'stage_three'
              : isStageOne || (isStageTwo && formSubmissionIntent === 'stage_one_task_only')
              ? isStageThree || isStageTwo
              : false,
          )

          setStage(stageOfApplication)
          setIsLoading(false)
        }

        if (userData?.response?.data && userData?.response?.data.message === 'Applicant does not exist') {
          setNoRecordFound(true)
          setIsLoading(false)
        } else if (userData?.response) {
          setIsLoading(false)
          setFailedRequest(true)
        }
      } catch (error) {}
    }

    if (!userEmail) {
      setIsLoading(false)
      setFailedRequest(true)
      return
    }

    fetchUserData()
  }, [userEmail, formSubmissionIntent])

  const showErrorFallBack = useMemo(
    () => failedRequest || noRecordFound || isLoading || restrictedAccess,
    [failedRequest, noRecordFound, isLoading, restrictedAccess],
  )

  return {
    userData,
    isLoading,
    noRecordFound,
    failedRequest,
    restrictedAccess,
    stage,
    submissionExpired,
    setSubmissionExpired,
    showErrorFallBack,
    hasSubmittedAlready,
  }
}

export { useFetchUser }
