import React from 'react'
import styles from './styles.module.css'

const TextArea = React.forwardRef(
  (
    {
      value,
      error = '',
      id = '',
      name = '',
      label = '',
      className = '',
      containerStyles = {},
      required = true,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={`${styles.inputContainer} ${className}`} style={{ ...containerStyles }}>
        {label && (
          <label className={styles.label} htmlFor={id}>
            {required ? <span className={styles.asterisk}>*</span> : null} {label}{' '}
            {!required ? <span className={styles.optionalMsg}>(optional)</span> : null}
          </label>
        )}
        <textarea className={styles.input} value={value} name={name} id={id} {...props} ref={ref} />
        {error && <p className={styles.error}>{error}</p>}
      </div>
    )
  },
)

export { TextArea }
