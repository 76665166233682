import React from 'react'
import styles from './styles.module.css'

const BackendTask = () => {
  return (
    <React.Fragment>
      <h4>Instructions</h4>
      <ol className={styles.list}>
        <li className={styles.item}>
          Solve <span className={styles.emphasis}>(1 && 2 || 3)</span>
        </li>
        <li className={styles.item}>Each solution should be in a separate file.</li>
        <li className={styles.item}>All solutions should in one repo</li>
        <li className={styles.item}>Submit the link to the repo</li>
      </ol>
      <hr />
      <h4>TASK ONE - Numbers in words</h4>
      <span className={`${styles.note} ${styles.info}`}>PROBLEM DESCRIPTION</span>
      <p>
        It occurs now and then in real life that people want to write about money, especially about a certain amount of
        money. If it comes to cheques or contracts for example some nations have laws that state that you should write
        out the amount in words additionally to the amount in numbers to avoid fraud and mistakes. So if you want to
        transfer 765,000 NGN to someone via cheque you have to fill out two fields: 765,000.00 NGN (amount in numbers)
        seven hundred and sixty five thousand naira (amount in words)
      </p>
      <hr />
      <ol className={styles.list}>
        <li className={styles.item}>
          Write a little converter class or function (depends on your favourite language and flavour) to convert numbers
          into words.
        </li>
        <li className={styles.item}>Your function should also be able to convert it back (vice-versa).</li>
        <li className={styles.item}>
          <span className={styles.emphasis}>Bonus:</span> Make It Test Driven 😉.
        </li>
        <hr />
      </ol>
      <div className={styles.link_container}>
        <span>
          <span className={styles.note}>NOTE</span> We entertain only unique solutions.
        </span>
      </div>
      <hr />
      <h4>TASK TWO - Code Cracker</h4>
      <span className={`${styles.note} ${styles.info}`}>PROBLEM DESCRIPTION</span>
      <p>
        Given an alphabet decryption key like the one below, create a program that can crack any message using the
        decryption keys.
      </p>
      <div className={styles.link_container}>
        <hr />
        <span>
          <span className={styles.emphasis}>AlPHABET</span>a b c d e f g h i j k l m n o p q r s t u v w x y z
        </span>
        <hr />
        <span>
          <span className={styles.emphasis}>DECRYPTION KEYS</span>

          {`£ * % & > < ! ) " ( @ a b c d e f g h i j k l m n o  `}
        </span>
        <hr />
      </div>

      <div className={styles.link_container}>
        <span>
          <span className={styles.note}>NOTE</span> You could also create an encryption program that will encrypt any
          message you give it using the key. We entertain only unique solutions.
        </span>
      </div>
      <hr />
      <h4>TASK THREE - Wallet</h4>
      <span className={`${styles.note} ${styles.info}`}>PROBLEM DESCRIPTION</span>
      <p>
        Given a Wallet containing Stocks, build a function that compute the value of wallet in a currency. The Stocks
        have a quantity and a StockType. The StockType can be for example Tesla Inc, ETH, BTC, and Microsoft
        Corporation. To value the portfolio in a currency you can use external api to provide rate exchanges (some are
        provided below). Some APIs can be used to provides rates: <br />
      </p>

      <a href="http://" className="underline">
        https://finance.google.com/{' '}
      </a>
      <hr />
    </React.Fragment>
  )
}

export { BackendTask }
