import React from 'react'
import styles from './styles.module.css'

const Checkbox = React.forwardRef(({ name = '', id = '', label = '', ...props }, ref) => {
  return (
    <div className={styles.checkbox_container}>
      <input type="checkbox" name={name} id={id} {...props} ref={ref} />
      <label htmlFor={id}>{label}</label>
    </div>
  )
})

export { Checkbox }
