import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as yup from 'yup'
import bezaoLogo from '../../assets/Bezao-logo.png'
import { TimeExpired } from '../../assets/icon-svgs'
import { CountDown } from '../../components/Countdown'
import { ErrorFallbackUI } from '../../components/ErrorFallbackUI'
import { TextInput } from '../../components/Input'
import { SubmitSuccessUI } from '../../components/SubmitSuccessUI'
import { useFetchUser } from '../../hooks/useFetchUser'
import { postRequest } from '../../services/httpService'
import { BackendTask } from './BackendTask'
import { FrontendTask } from './FrontendTask'
import styles from './styles.module.css'

// Yup Validation Schema
const validationSchema = yup.object().shape(
  {
    github_link: yup
      .string()
      .matches(
        /((git|ssh|(http)?(s)?)|(git@[\w.]+))(:(\/\/)?)([\w.@:/-~]+)(.git)(\/)?/g,
        'Enter valid github url. Ensure there is "https://',
      )
      .required('This field is required.'),

    appLink: yup.string().when('appLink', (value, schema) => {
      if (value?.length > 0) {
        return yup.string().optional()
      }
    }),
  },
  [
    ['github_link', 'github_link'],
    ['appLink', 'appLink'],
  ],
)

const TaskSubmissionPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // State Variables
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false)
  const userEmail = useMemo(() => new URLSearchParams(location.search).get('email'), [location.search])
  const {
    userData,
    isLoading,
    noRecordFound,
    failedRequest,
    stage,
    submissionExpired,
    setSubmissionExpired,
    showErrorFallBack,
    hasSubmittedAlready,
  } = useFetchUser({ userEmail, formSubmissionIntent: 'stage_one_task_only' })

  const getExpiryDate = useCallback(hasExpired => setSubmissionExpired(hasExpired), [setSubmissionExpired])
  const isStageOne = useMemo(() => stage === 'stage_one', [stage])
  const isStageThree = useMemo(() => stage === 'stage_three', [stage])
  const hideFormInstructions = useMemo(
    () => !submissionExpired && !hasSubmittedAlready,
    [hasSubmittedAlready, submissionExpired],
  )

  const requestStatus = useMemo(
    () => ({ failedRequest, noRecordFound, isLoading }),
    [failedRequest, noRecordFound, isLoading],
  )

  // Content variables
  const submissionContent = useMemo(
    () =>
      submissionExpired || hasSubmittedAlready
        ? {
            greeting: 'Good day',
            header: hasSubmittedAlready ? 'Oops! You are in the wrong stage' : 'Ooops! Portal Closed For Submission',
            description: isStageOne
              ? `You can no longer submit your task as the deadline for which this was allowed has elapsed.
                We wish you better luck next time ✌🏽🙂`
              : `You have already submitted your task for this stage, please check your email for further instructions or click on the button below to move to the next steps.`,
          }
        : {
            greeting: 'Welcome',
            header: 'Submission Deadline',
            description: `The stop of this countdown will trigger a deactivation of the submission portal. 
            Please do well to submit before then 😀.`,
          },
    [submissionExpired, isStageOne, hasSubmittedAlready],
  )

  // Button handlers
  const handleFailedSubmissionAction = useCallback(
    event => isStageThree && navigate(`/personality_final_submission?email=${userEmail}`),
    [isStageThree, navigate, userEmail],
  )

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { github_link: 'https://', appLink: 'https://' },
  })

  const [submitStatus, setSubmitStatus] = useState('idle')
  const isSubmittingTask = submitStatus === 'loading'

  const handleTaskSubmitAction = handleSubmit(async data => {
    setSubmitStatus('loading')
    try {
      const response = await postRequest({
        url: '/submit-test-link',
        data: {
          email: userEmail,
          link: data.github_link,
          appLink: data.appLink,
          stage: 'stage_one',
        },
      })

      if (response?.response) {
        setError('github_link', { message: response?.response?.data?.message })
        toast(response?.response?.data?.message, {
          progressClassName: styles.progress,
          type: 'error',
          toastId: 'error-toast',
        })
      } else {
        toast('Submitted Successfully!', {
          progressClassName: styles.progress,
          type: 'success',
          toastId: 'success-toast',
        })
        reset()
        setSubmittedSuccessfully(true)
      }
    } catch (error) {
    } finally {
      setSubmitStatus('idle')
    }
  })

  return (
    <div className={styles.container}>
      <nav className={styles.nav} onClick={() => navigate('/')}>
        <img src={bezaoLogo} alt="" />{' '}
      </nav>
      <section
        className={`
        ${styles.registrationContainer} 
        ${isLoading ? styles.loading : ''} 
        ${noRecordFound || failedRequest ? styles.noRecordFound : ''}`}
      >
        <React.Fragment>
          {submittedSuccessfully ? (
            <SubmitSuccessUI message="Your entry has been recorded. Please check your mail for futher instructions." />
          ) : (
            <React.Fragment>
              {showErrorFallBack ? (
                <ErrorFallbackUI requestStatus={requestStatus} styles={styles} userEmail={userEmail} />
              ) : (
                <React.Fragment>
                  <div className={styles.formWrapper}>
                    <section className={styles.content}>
                      <h2>
                        {submissionContent.greeting},{' '}
                        <span>
                          {userData?.full_name} {!submissionExpired && isStageOne ? <> &#128075;</> : <>😬</>}
                        </span>
                      </h2>
                      <hr />
                      <h4>{submissionContent.header}</h4>
                      <p>{submissionContent.description}</p>
                      {!hasSubmittedAlready && (
                        <CountDown expiryDate={userData?.stage_expiration?.endDate} getExpiryDate={getExpiryDate} />
                      )}
                      {hideFormInstructions ? (
                        <React.Fragment>
                          <span className={styles.updateText}>
                            <span className={styles.note}>NOTE</span>You can only submit once for this stage.
                          </span>
                          <hr />
                          {userData?.programme_stack?.toLowerCase() === 'frontend' ? <FrontendTask /> : <BackendTask />}
                        </React.Fragment>
                      ) : (
                        <div className={styles.submittedNotice}>
                          {hasSubmittedAlready && <TimeExpired />}
                          <button
                            className={`bzo-hero-button blue-variant ${styles.button}`}
                            onClick={handleFailedSubmissionAction}
                          >
                            {isStageThree ? 'Go to Next Stage' : 'Go Home'}
                          </button>
                        </div>
                      )}
                    </section>
                    {hideFormInstructions && (
                      <form className={styles.form} onSubmit={handleTaskSubmitAction}>
                        <TextInput
                          {...register('github_link')}
                          error={errors?.['github_link']?.message}
                          type="text"
                          id="github_link"
                          name="github_link"
                          placeholder="Enter task solution link"
                          label="Github Repo Link"
                          className={styles.textInput}
                        />

                        <TextInput
                          {...register('appLink')}
                          error={errors?.['appLink']?.message}
                          type="text"
                          id="appLink"
                          name="appLink"
                          placeholder="Enter task solution link"
                          label="App Link"
                          required={false}
                          className={styles.textInput}
                        />
                        <hr />
                        <button
                          disabled={isSubmittingTask}
                          type="submit"
                          className={`bzo-hero-button blue-variant ${isSubmittingTask ? styles.loading : ''} ${
                            styles.button
                          } `}
                        >
                          Submit {isSubmittingTask && <span className="loader"></span>}
                        </button>
                      </form>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>

        <div className={styles.submitbg}></div>
      </section>
      <ToastContainer position="bottom-right" />
    </div>
  )
}

export { TaskSubmissionPage }
