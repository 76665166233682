import { yupResolver } from '@hookform/resolvers/yup'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import styles from '../pages/ApplicationPage/styles.module.css'
import { getRequest, postRequest } from '../services/httpService'

const useApplicationForm = targetDate => {
  //  Validation schema for form
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        first_name: yup.string().required('Firstname is required.'),
        last_name: yup.string().required('Lastname is required.'),
        email: yup.string().email('Email is invalid').required('This field is required.'),
        age: yup.string().required('Age is required.'),
        phone: yup.string().required('Phone number is required.'),
        address: yup.string().required('Address is a required field.'),
        github_profile: yup
          .string()
          .matches(/^(http(s?):\/\/)?(www\.)?github\.([a-z])+\/([A-Za-z0-9]{1,})+\/?$/i, 'Enter valid github URL.')
          .required('Github profile is required.'),

        gender: yup.mixed().required('Gender is a necessary field.'),
        programme_stack: yup.mixed().required('Please select a track.'),
        what_describes_you_best: yup.mixed().required('Your education level is needed.'),
        skill_rating_description: yup.mixed().required('Your programming experience is mandatory.'),
        cv_upload: yup.mixed().required('File is required.'),
        medical_condition: yup.mixed().required('Health status is required.'),
        medical_condition_text: yup.string().when('medical_condition', value => {
          if (value === 'Yes') {
            return yup.string().required('Medical condition is required.')
          }
        }),
        other_languages_text: yup.string().when('language_framework', value => {
          if (value && value?.includes('Others')) {
            return yup.string().required('This is a required field.')
          }
        }),
        what_describes_you_best_text: yup.string().when('what_describes_you_best', value => {
          if (value?.includes('Others')) {
            return yup.string().required('This is a required field.')
          }
        }),
        skill_rating_description_others: yup.string().when('skill_rating_description', value => {
          if (value?.includes('Others')) {
            return yup.string().required('This is a required field.')
          }
        }),
        greatest_achievement: yup.string().required('Please do tell your greatest achievement.'),
        coding_non_related_book_desc: yup.string().required('Holla? We would love to know about this also.'),
      }),
    [],
  )

  const [taskSubmissionStatus, setTaskSubmissionStatus] = useState('idle')
  const [appSubmittedSuccessfully, setAppSubmittedSuccessfully] = useState(false)

  // React hook form
  const reactHookFormVariables = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' })
  const { setError, reset, watch } = reactHookFormVariables
  const formValues = watch()

  // Check for errors in checkboxes
  const hideCheckBoxesError = useMemo(() => {
    const checkForEmptiness = value => {
      if (Array.isArray(value)) {
        return value.length >= 1
      }
      return !value
    }
    return {
      code_language_used: checkForEmptiness(formValues.code_language_used),
    }
  }, [formValues])

  // List of check boxes on page
  const CHECKBOXES_LIST = useMemo(
    () => ({
      code_language_used: ['PHP', 'JavaScript', 'C#', 'Java'],
      language_framework: [
        'AngularJS',
        'ReactJS',
        'React Native',
        'VueJS',
        'ExpressJS',
        'Laravel',
        'Groovy',
        'Spring',
        '.NET Core',
        'Microsoft .NET MVC',
        'Others',
      ],
    }),
    [],
  )

  // List of radio inputs
  const RADIOINPUT_LIST = useMemo(
    () => ({
      medical_condition: ['Yes', 'No'],
      programme_stack: ['Frontend', 'Backend'],
      gender: ['Male', 'Female', 'Prefer not to say'],
      what_describes_you_best: [
        'I am a graduate',
        'I am an undergraduate',
        'I did not go a university',
        'I am a corper',
        'Others',
      ],
      skill_rating_description: [
        'I have written just enough code to test my skills',
        'I have developed software before but it is just in my laptop',
        'I have participated in the developemnt of software that is currently in use',
        'Others',
      ],
    }),
    [],
  )

  // Form submit handler
  const handleApplicationSubmission = async data => {
    if (taskSubmissionStatus === 'pending') return
    if (data.cv_upload.length < 1) {
      setError('cv_upload', {
        message: 'File is required.',
      })
      return
    }

    setTaskSubmissionStatus('pending')

    data.full_name = `${data.first_name} ${data.last_name}`

    // Formatting of request body
    const requiredFields = [
      'full_name',
      'email',
      'gender',
      'age',
      'phone',
      'address',
      'what_describes_you_best',
      'skill_rating_description',
      'github_profile',
      'code_language_used',
      'language_framework',
      'programme_stack',
      'greatest_achievement',
      'coding_non_related_book_desc',
      'medical_condition',
      'what_describes_you_best_text',
      'medical_condition_text',
      'skill_rating_description_others',
    ]

    const keysValuePairsToAppendTo = {
      what_describes_you_best_text: 'what_describes_you_best',
      medical_condition_text: 'medical_condition',
      skill_rating_description_others: 'skill_rating_description',
    }

    const requestBody = {}

    for (let key of requiredFields) {
      if (Object.keys(keysValuePairsToAppendTo).includes(key)) {
        if (!!data[key]) {
          requestBody[keysValuePairsToAppendTo[key]] = `${requestBody[keysValuePairsToAppendTo[key]]} - ${data[key]}`
        }
        continue
      }

      if (key === 'language_framework' && data[key]?.length >= 1) {
        requestBody[key] = [...data[key]]
        continue
      }

      requestBody[key] = data[key]
    }

    if (data.other_languages_text) {
      for (let language of data.other_languages_text.split(',')) {
        if (!requestBody['language_framework'].includes(language)) {
          requestBody['language_framework'].push(language.trim())
        }
      }

      requestBody['language_framework'] = requestBody['language_framework'].filter(
        language => language.toLowerCase() !== 'others',
      )
    }

    // File upload form data
    const formData = new FormData()
    Array.from(data.cv_upload).forEach(file => {
      formData.append('file', file)
    })

    try {
      const checkIfApplicantExists = await getRequest({ url: `/get-registered-applicant/timer?email=${data.email}` })
      if (checkIfApplicantExists?.data?.success) {
        toast('Application with this email already exists!', {
          progressClassName: styles.progress,
          type: 'error',
          toastId: 'error-toast',
          autoClose: true,
        })

        return
      }

      const uploadedFile = await postRequest({ url: '/media-upload', data: formData })
      if (uploadedFile?.data) {
        const cv_details = uploadedFile.data.data[0]
        requestBody.cv_url = {
          path: cv_details.path,
          key: cv_details.publicId,
        }

        toast('File upload successful. Submitting application. Please wait...', {
          autoClose: false,
          progressClassName: styles.progress,
          type: 'success',
          toastId: 'success-toast',
        })
      } else {
        toast('File upload failed. Try again with a smaller file size (=< 1MB) or check your internet connection.', {
          progressClassName: styles.progress,
          type: 'error',
          toastId: 'error-toast',
          autoClose: false,
        })

        return
      }

      const applicationSubmitRes = await postRequest({ url: '/applicants-registration', data: requestBody })
      if (applicationSubmitRes?.data) {
        reset()
        setAppSubmittedSuccessfully(true)
        toast('Application Submitted Succesfully', {
          autoClose: false,
          progressClassName: styles.progress,
          type: 'success',
          toastId: 'success-toast',
        })
      } else {
        toast(applicationSubmitRes?.response?.data?.message, {
          progressClassName: styles.progress,
          type: 'error',
          toastId: 'error-toast',
          autoClose: false,
        })

        return
      }
    } catch (error) {
    } finally {
      setTaskSubmissionStatus('idle')
    }
  }

  return {
    CHECKBOXES_LIST,
    RADIOINPUT_LIST,
    validationSchema,
    reactHookFormVariables,
    hideCheckBoxesError,
    formValues,
    appSubmittedSuccessfully,
    taskSubmissionStatus,
    handleApplicationSubmission,
  }
}

export default useApplicationForm
