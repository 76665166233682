import React from 'react'
import { useNavigate } from 'react-router'
import { FailedRequest, NotFound, TimeExpired } from '../../assets/icon-svgs'

const ErrorFallbackUI = ({ userEmail = '', styles, requestStatus = {} }) => {
  const navigate = useNavigate()

  // The loading states used in this application
  const { failedRequest = true, noRecordFound, isLoading, restrictedAccess } = requestStatus

  const handleFailedRequest = () => {
    if (!userEmail) {
      navigate(-1)
      return
    }

    window.location.reload()
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          <span className="loader"></span>
          <h2>
            Loading info for, <span>{userEmail}.</span>
          </h2>
        </div>
      ) : failedRequest ? (
        <div className={styles.emptyContainer}>
          <FailedRequest />
          <h2>
            {!userEmail ? (
              <React.Fragment>
                <span>Invalid Registration Link. </span>Ensure that you clicked the right email sent to your mail box
                for this stage in your registration.
              </React.Fragment>
            ) : (
              'Something went wrong. Retry?'
            )}
          </h2>
          <button className={`bzo-hero-button blue-variant ${styles.button}`} onClick={handleFailedRequest}>
            {!userEmail ? 'Go back' : 'Retry'}
          </button>
        </div>
      ) : noRecordFound ? (
        <div className={styles.emptyContainer}>
          <NotFound />
          <h2>
            No records found for, <span>{userEmail}.</span>
          </h2>
          <button
            className={`bzo-hero-button blue-variant ${styles.button}`}
            onClick={() => navigate('/', { replace: true })}
          >
            Go Home
          </button>
        </div>
      ) : restrictedAccess ? (
        <div className={styles.emptyContainer}>
          <TimeExpired />
          <h2>
            You are not eligible to view this page. Please ensure that you clicked the right link or contact us at{' '}
            <a href="mailto:hello@genesystechhub.com" className="underline">
              hello@genesystechhub.com
            </a>{' '}
            if this is an invalid message. Thank you.
          </h2>
          <button
            className={`bzo-hero-button blue-variant ${styles.button}`}
            onClick={() => navigate('/', { replace: true })}
          >
            Go Home
          </button>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export { ErrorFallbackUI }
