import axios from 'axios'

axios.defaults.baseURL = 'https://bezao-api.herokuapp.com/api/v1'

// Log a user out with an expired token
axios.interceptors.response.use(
  response => response,
  error => error,
)

// Axios request functions
export async function getRequest(request) {
  return await axios.get(request.url, request.config)
}

export async function postRequest(request) {
  return await axios.post(request.url, request.data, request.config)
}

export async function putRequest(request) {
  return await axios.put(request.url, request.data)
}
