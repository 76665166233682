import React from 'react'
import styles from './styles.module.css'

const TextInput = React.forwardRef(
  ({ value, error = '', id = '', name = '', label = '', className = '', required = true, ...props }, ref) => {
    return (
      <div className={`${styles.inputContainer} ${className}`}>
        {label && (
          <label className={styles.label} htmlFor={id}>
            {required ? <span className={styles.asterisk}>*</span> : null} {label}{' '}
            {!required ? <span className={styles.optionalMsg}>(optional)</span> : null}
          </label>
        )}
        <input className={styles.input} value={value} name={name} id={id} {...props} ref={ref} />
        {error && <p className={styles.error}>{error}</p>}
      </div>
    )
  },
)

export { TextInput }
