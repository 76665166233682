import { ReactComponent as ApplicationSubmitted } from './application_submitted.svg'
import { ReactComponent as ApplySVG } from './apply_svg.svg'
import { ReactComponent as FailedRequest } from './failed_request.svg'
import { ReactComponent as NotFound } from './not_found.svg'
import { ReactComponent as TimeExpired } from './time_expired_1.svg'

const hamburgerIcon = (view, color) => {
  return !view ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
      <path
        d="M16 288h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16z"
        className="fa-secondary"
        stroke={color || 'white'}
        fill={color || 'white'}
        opacity={0.4}
        fontSize="20px"
      />
      <path
        d="M432 384H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm0-320H16A16 16 0 0 0 0 80v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V80a16 16 0 0 0-16-16z"
        className="fa-primary"
        stroke={color || 'white'}
        fill={color || 'white'}
      />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
      <path
        d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
        className="fa-secondary"
        stroke={color || 'white'}
        fill={color || 'white'}
      />
      <path
        d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"
        className="fa-primary"
        stroke={color || 'white'}
        fill={color || 'white'}
      />
    </svg>
  )
}

export { hamburgerIcon, NotFound, FailedRequest, TimeExpired, ApplySVG, ApplicationSubmitted }
