import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import * as yup from 'yup'
import bezaoLogo from '../../assets/Bezao-logo.png'
import { TimeExpired } from '../../assets/icon-svgs'
import { ErrorFallbackUI } from '../../components/ErrorFallbackUI'
import { TextInput } from '../../components/Input'
import { SubmitSuccessUI } from '../../components/SubmitSuccessUI'
import { useFetchUser } from '../../hooks/useFetchUser'
import { postRequest } from '../../services/httpService'
import styles from './styles.module.css'

const validationSchema = yup.object().shape(
  {
    github_link: yup
      .string()
      .matches(
        /((git|ssh|(http)?(s)?)|(git@[\w.]+))(:(\/\/)?)([\w.@:/-~]+)(.git)(\/)?/g,
        'Enter valid github url. Please ensure there is "https://" in your URL. ',
      )
      .required('This field is required.'),

    personality_link: yup
      .string()
      .matches(
        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        'Enter a valid URL.',
      )
      .required(),
  },
  [
    ['github_link', 'github_link'],
    ['personality_link', 'personality_link'],
  ],
)

const TaskAndPersonalitySubmissionPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // State Variables
  const userEmail = useMemo(() => new URLSearchParams(location.search).get('email'), [location.search])
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false)
  const {
    userData,
    isLoading,
    noRecordFound,
    failedRequest,
    restrictedAccess,
    stage,
    submissionExpired,
    setSubmissionExpired,
    showErrorFallBack,
    hasSubmittedAlready,
  } = useFetchUser({ userEmail })

  const getExpiryDate = useCallback(hasExpired => setSubmissionExpired(false), [setSubmissionExpired])
  const isStageOne = useMemo(() => stage === 'stage_one', [stage])
  const isStageTwo = useMemo(() => stage === 'stage_two', [stage])
  const isStageThree = useMemo(() => stage === 'stage_three', [stage])
  const hideFormInstructions = useMemo(
    () => !submissionExpired && !hasSubmittedAlready,
    [hasSubmittedAlready, submissionExpired],
  )

  const requestStatus = useMemo(
    () => ({ failedRequest, noRecordFound, isLoading, restrictedAccess }),
    [failedRequest, noRecordFound, isLoading, restrictedAccess],
  )

  // Content variables
  const submissionContent = useMemo(
    () =>
      submissionExpired || hasSubmittedAlready
        ? {
            greeting: hasSubmittedAlready ? 'Hello' : 'Good day',
            header: isStageOne ? 'Ooops, wrong page!' : 'Ooops! Portal Closed For Submission',
            description: hasSubmittedAlready
              ? 'You have already submitted your answers for this stage of your application. We will communicate the next steps via email. Please check your email for updates. Thank you. ✌🏽'
              : isStageOne
              ? `You haven't submitted your task for stage one. Click on the button below to visit submission portal.`
              : `Either the deadline has elapsed or have exceeded the limit for updating your task.`,
          }
        : {
            greeting: 'Welcome again',
            header: 'Submission Deadline',
            description: `The stop of this countdown will trigger a deactivation of the submission portal. 
            Please do well to submit before then 😀`,
          },
    [submissionExpired, isStageOne, hasSubmittedAlready],
  )

  // Button handlers
  const handleFailedSubmissionAction = useCallback(
    event => {
      if (isStageOne || isStageTwo) {
        navigate('/', { replace: true })
        return
      }
    },
    [isStageOne, isStageTwo, navigate],
  )

  // React hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: { github_link: 'https://', personality_link: 'https://' },
  })

  const [submitStatus, setSubmitStatus] = useState('idle')
  const isSubmittingTask = submitStatus === 'loading'

  const handleTaskSubmitAction = handleSubmit(async data => {
    const allowedEmailsForSubmission = [
      'praise609@gmail.com',
      'hamsaikemefuna@gmail.com',
      'ajeigbekehinde160@gmail.com',
      'drfrancismicheal@gmail.com',
      'favouralex084@gmail.com',
      'chukwuchidieberejohn@gmail.com',
      'ghena.bright@gmail.com',
      'amaefulegregory@gmail.com',
      'mikyesdomkat04@gmail.com',
      'anekemikeobiora2@gmail.com',
      'collinsihezie6@gmail.com',
      'chiiderah@gmail.com',
      'chiemelapromise30@gmail.com',
      'datonarinze@gmail.com',
      'biola.akinyele@gmail.com',
      'cikechukwujohn@gmail.com',
      'uchevictory4@gmail.com',
      'kolawoletamilore1@gmail.com',
      'kingsley019@yahoo.com',
      'abelbaba094121@gmail.com',
      'oseni_eniola@yahoo.com',
      'mauricechisom275@gmail.com',
      'johnndnzekwejnr@yahoo.com',
      'alexezetech@gmail.com',
      'topeenikankiselu@gmail.com',
      'ebube4im@gmail.com',
      'nectuzzy4u@gmail.com',
      'chibuikeuzoechina@gmail.com',
    ]

    if (!allowedEmailsForSubmission.includes(userEmail)) {
      toast("You're not allowed to submit an entry!", {
        progressClassName: styles.progress,
        type: 'error',
        toastId: 'error-toast',
        autoClose: false,
      })

      return
    }

    setSubmitStatus('loading')
    if (!isStageThree) return

    try {
      const response = await postRequest({
        url: '/submit-test-link',
        data: {
          email: userEmail,
          link: data.github_link,
          personality_link: data.personality_link,
          stage: 'stage_three',
        },
      })

      if (response?.response) {
        setError('github_link', { message: response?.response?.data?.message })
        toast(response?.response?.data?.message, {
          progressClassName: styles.progress,
          type: 'error',
          toastId: 'error-toast',
          autoClose: false,
        })
      } else {
        toast('Submitted Successfully!', {
          progressClassName: styles.progress,
          autoClose: false,
          type: 'success',
          toastId: 'success-toast',
        })
        reset()
        setSubmittedSuccessfully(true)
      }
    } catch (error) {
    } finally {
      setSubmitStatus('idle')
    }
  })

  return (
    <div className={styles.container}>
      <nav className={styles.nav} onClick={() => navigate('/')}>
        <img src={bezaoLogo} alt="" />{' '}
      </nav>
      <section
        className={`
        ${styles.registrationContainer} 
        ${isLoading ? styles.loading : ''} 
        ${showErrorFallBack ? styles.noRecordFound : ''}`}
      >
        {submittedSuccessfully ? (
          <SubmitSuccessUI />
        ) : (
          <React.Fragment>
            {showErrorFallBack ? (
              <ErrorFallbackUI requestStatus={requestStatus} styles={styles} userEmail={userEmail} />
            ) : (
              <React.Fragment>
                <div className={styles.formWrapper}>
                  <section className={styles.content}>
                    <h2>
                      {submissionContent.greeting},{' '}
                      <span>
                        {userData?.full_name} {submissionExpired && (isStageOne || isStageTwo) ? <> 😬</> : <> 🙂</>}
                      </span>
                    </h2>
                    <hr />
                    <h4>{submissionContent.header}</h4>
                    <p>{submissionContent.description}</p>
                    {/* {!hasSubmittedAlready && (
                      <CountDown expiryDate={userData?.stage_expiration?.endDate} getExpiryDate={getExpiryDate} />
                    )} */}

                    {hideFormInstructions ? (
                      <React.Fragment>
                        <span className={styles.updateText}>
                          <span className={styles.note}>NOTE</span> You can only submit once for this stage.
                        </span>
                        <hr />

                        <h4>Personality Test Instructions</h4>
                        <ol className={styles.list}>
                          <li className={styles.item}>
                            Access The personality test link{' '}
                            <a
                              href="https://www.16personalities.com/free-personality-test"
                              target="_blank"
                              rel="noreferrer"
                            >
                              here
                            </a>
                          </li>
                          <li className={styles.item}>
                            Take the test provided above. Once the test is completed, your results will be sent to the
                            email you provided to the test vendors before starting.
                          </li>
                          <li className={styles.item}>
                            In the results email, copy the link to your results and paste it in the input field below.
                            Remember that to submit this form, both the personality test and C# tests have to be
                            completed.
                          </li>
                        </ol>

                        <TextInput
                          {...register('personality_link')}
                          error={errors?.['personality_link']?.message}
                          type="text"
                          id="personality_link"
                          name="personality_link"
                          label="Link to Personality Test"
                          className={styles.textInput}
                          placeholder="Enter link to personality test results"
                        />

                        <hr />

                        <h4>C# Task Instructions</h4>

                        <ol className={styles.list}>
                          <li className={styles.item}>
                            Solve Questions <span className={styles.emphasis}>(1 && 2 && 3)</span> below.
                          </li>
                          <li className={styles.item}>Each solution should be in a separate file.</li>
                          <li className={styles.item}>All solutions should in one repo</li>
                          <li className={styles.item}>Submit the link to the repo</li>
                        </ol>
                        <hr />

                        <h4>C# Questions</h4>
                        <p></p>
                        <ol className={styles.list}>
                          <li className={styles.item}>Write a program that prints the next 20 leap years.</li>
                          <li className={styles.item}>
                            Write a guessing game where the user has to guess a secret number. After every guess the
                            program tells the user whether their number was too large or too small. At the end the
                            number of tries needed should be printed. It counts only as one try if they input the same
                            number multiple times consecutively.
                          </li>
                          <li className={styles.item}>
                            Write a program that translates a text to Pig Latin and back. English is translated to Pig
                            Latin by taking the first letter of every word, moving it to the end of the word and adding
                            ‘ay’. <span className={styles.emphasis}>“The quick brown fox”</span> becomes{' '}
                            <span className={styles.emphasis}>“Hetay uickqay rownbay oxfay”.</span>
                          </li>
                        </ol>
                        <hr />

                        <TextInput
                          {...register('github_link')}
                          error={errors?.['github_link']?.message}
                          type="text"
                          id="github_link"
                          name="github_link"
                          label="Github Repo Link"
                          className={styles.textInput}
                          placeholder="Enter github repo link"
                        />

                        <hr />
                      </React.Fragment>
                    ) : (
                      <div className={styles.submittedNotice}>
                        {hasSubmittedAlready && <TimeExpired />}
                        <button
                          className={`bzo-hero-button blue-variant ${styles.button}`}
                          onClick={handleFailedSubmissionAction}
                        >
                          Go Home
                        </button>
                      </div>
                    )}
                  </section>

                  {hideFormInstructions && (
                    <form className={styles.form} onSubmit={handleTaskSubmitAction}>
                      <button
                        disabled={isSubmittingTask}
                        type="submit"
                        className={`bzo-hero-button blue-variant ${isSubmittingTask ? styles.loading : ''} ${
                          styles.button
                        } `}
                      >
                        Submit {isSubmittingTask && <span className="loader"></span>}
                      </button>
                    </form>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div className={styles.submitbg}></div>
      </section>
      <ToastContainer position="bottom-right" />
    </div>
  )
}

export { TaskAndPersonalitySubmissionPage }
