import React, { useState } from 'react'
import angularLogo from '../../assets/Angular_full_color_logo.svg.png'
import bezaoLogo from '../../assets/Bezao-logo.png'
import dotnetLogo from '../../assets/dotnet-logo-png-transparent.png'
import bottom from '../../assets/flow chart arrows/bottom.png'
import left from '../../assets/flow chart arrows/left-1.png'
import right from '../../assets/flow chart arrows/right-1.png'
import genesysLogo from '../../assets/genesys-logo.png'
import howItWorks from '../../assets/how-it-works.png'
import knowledgeOfProg from '../../assets/Knowledge-of-programming.png'
import livingInNig from '../../assets/living-in-nigeria.png'
import physicallyAvail from '../../assets/physically-available.png'
import portfolio from '../../assets/sizeable-portfolio.png'
import teneceLogo from '../../assets/Tenece-logo.png'
import uniDegree from '../../assets/unidegree.png'
import willingToWork from '../../assets/willing-to-work.png'
import LandingNav from '../../components/landingNav/landingNav'

import { Accordion, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { FAQ } from '../../data'

const CheckerState = ({onGoing}) => {
  const navigate = useNavigate()
  return (
    <>
       {
    onGoing ?  
   <button
    className="bzo-hero-button blue-variant"
    onClick={() => navigate("/application_form")}
  >
    Register For This Cohort
  </button> : 
    <button
    disabled
    className="bzo-hero-button blue-variant"
  >
    Application For 2022 Cohort has Ended <br /> Next Cohort Coming
    Soon!
  </button>
  }
    </>
  )
}

const LandingPage = () => {
  const [onGoing, setOnGoing] = useState(true);
  return (
    <div className="bzo-app-container">
      <a href="#landing-nav" id="scroll-to-top">
        Top
      </a>
      <header>
        <LandingNav />
      </header>
      <main className="bzo-main-section">
        <section className="bzo-main-hero-section bzo-container">
          <aside className="main-hero-content">
            <h1>
              <span>BE LIVE.</span>
              <span>BE DIFFERENT.</span>
              <span>BE ZAO.</span>
            </h1>

            <p>
              Become an industry standard software developer by joining our 8
              month physical intensive software training program.
            </p>

            {/* <button
              disabled
              className="bzo-hero-button"
              onClick={() => navigate("/application_form")}
            >
              Application For 2022 Cohort has Ended <br /> See you next year!
            </button> */}
          </aside>
        </section>
        <section
          className="bzo-main-program-description-section bzo-container"
          id="about"
        >
          <aside>
            <p>
              Between Zero and One - BEZAO, is a software training program
              designed to educate and support market ready software developers
              for our indigenous software development industry. The first phase
              focuses on providing adequate software development education for
              the participants. In the second phase, we place the participants
              in our partners’ software teams to help them drive productivity.
              We are providing you with everything you need for your movement
              between zero and one.
            </p>
          </aside>
          <aside>
            <h1>
              <span>BE LIVE</span>
              <span>BE-COME</span>
              <span>BE ZAO</span>
            </h1>
          </aside>
        </section>
        <section className="bzo-section-introduction" id="how-it-works">
          <img src={howItWorks} alt="" />
          <h1>How it works</h1>
        </section>
        <section className="bzo-how-it-works">
          <h2>
            We have spent a great deal of time and resources to develop a robust
            software development curriculum that covers Microsoft .NET and
            AngularJS. The program will also help you gain other soft skills
            that will exponentially increase your employability.
          </h2>
          <div className="how-it-works--steps__container">
            <aside className="how-it-works--steps">
              <div className="step 1" data-aos="flip-up">
                <span>
                  1
                  <img
                    src={right}
                    alt=""
                    className="uncompressed-steps-arrow"
                  />
                </span>
                <h3>Apply Online</h3>
                <p>Fill your correct details in the form we’ve provided</p>
              </div>
              <div className="step 2" data-aos="flip-up">
                <span>
                  2
                  <img
                    className="uncompressed-steps-arrow"
                    src={right}
                    alt=""
                  />
                  <img className="compressed-steps-arrow" src={bottom} alt="" />
                </span>

                <h3>Coding Test I</h3>
                <p>
                  Show us what you can do in your specified area of expertise
                </p>
              </div>
              <div className="step 3 step_3" data-aos="flip-up">
                <span>
                  3
                  <img className="compressed-steps-arrow" src={bottom} alt="" />
                </span>
                <h3>Coding Test II</h3>
                <p>
                  We give you some study materials and test your teachability.
                </p>
              </div>
            </aside>
            <img src={bottom} alt="" />
            <aside className="how-it-works--steps section-2">
              <div className="step 6" data-aos="flip-up">
                <span>
                  6
                  <img className="compressed-steps-arrow" src={bottom} alt="" />
                  <img src={left} alt="" className="uncompressed-steps-arrow" />
                </span>
                <h3>Get Hired Full-time!</h3>
                <p>Get an employment letter</p>
              </div>
              <div className="step 5" data-aos="flip-up">
                <span>
                  5
                  <img src={left} alt="" className="uncompressed-steps-arrow" />
                  <img className="compressed-steps-arrow" src={bottom} alt="" />
                </span>
                <h3>Start Your Internship</h3>
                <p>Begin your Bezao journey!</p>
              </div>
              <div className="step " data-aos="flip-up">
                <span>
                  4
                  <img className="compressed-steps-arrow" src={bottom} alt="" />
                </span>
                <h3>Physical/Oral Interview</h3>
                <p>You’ll be invited for a face-to face interview</p>
              </div>
            </aside>
          </div>
            <CheckerState onGoing={onGoing} />
          </section>
        <section className="bzo-section-introduction" id="learning">
          <img src={howItWorks} alt="" />
          <h1>What you’ll learn</h1>
        </section>
        <section className="bzo-what-to-learn">
          <aside className="what-to-learn--logos">
            <img src={dotnetLogo} alt="" />
            <img style={{ height: "12rem" }} src={angularLogo} alt="" />
          </aside>
          {/* <button
            disabled
            className="bzo-hero-button blue-variant"
            onClick={() => navigate("/application_form")}
          >
            Application For 2022 Cohort has Ended
          </button> */}
        </section>
        <section className="bzo-section-introduction" id="requirements">
          <img src={howItWorks} alt="" />
          <h1>WHAT ARE THE REQUIREMENTS</h1>
        </section>
        <section className="bzo-bezao-requirements bzo-container">
          <div className="requirement-item-container">
            <aside className="requirement-item" data-aos="zoom-in-up">
              <img src={uniDegree} alt="" />
              <p>
                You should have a University Degree and be done with NYSC or any
                equivalent program
              </p>
            </aside>
            <aside className="requirement-item" data-aos="zoom-in-up">
              <img src={portfolio} alt="" />
              <p>
                You should have some level of knowledge in any
                coding/programming language
              </p>
            </aside>
            <aside className="requirement-item" data-aos="zoom-in-up">
              <img src={knowledgeOfProg} alt="" />
              <p>You should have a sizeable Portfolio you can share</p>
            </aside>
            <aside className="requirement-item" data-aos="zoom-in-up">
              <img src={livingInNig} alt="" />
              <p>You should be living in Nigeria </p>
            </aside>
            <aside className="requirement-item" data-aos="zoom-in-up">
              <img src={physicallyAvail} alt="" />
              <p>You must be physically available for at least 8 months </p>
            </aside>
            <aside className="requirement-item" data-aos="zoom-in-up">
              <img src={willingToWork} alt="" />
              <p>You should be willing and ready to learn & work</p>
            </aside>
          </div>
          <CheckerState onGoing={onGoing} />
        </section>
        <section className="bzo-section-introduction FAQ" id="faqs">
          <img src={howItWorks} alt="" />
          <h1>FAQs</h1>
        </section>

        <section className="bzo-bezao-FAQ  bzo-container">
          <h1>Get in. Learn A lot. Get a job</h1>
          <Accordion defaultActiveKey="0">
            {FAQ.map((item, i) => {
              return (
                <Card key={i + 1}>
                  <Accordion.Toggle as={Card.Header} eventKey={i + 1}>
                    <h1>{item.header}</h1>
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.25 18.75V10H18.75V18.75H10V21.25H18.75V30H21.25V21.25H30V18.75H21.25Z"
                        fill="#626262"
                      />
                    </svg>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={i + 1}>
                    <Card.Body>
                      {item.body}
                      <br />
                      {i + 1 === 2 && <CheckerState onGoing={onGoing} />}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </section>
        <section className="bzo-facilitators bzo-container">
          <p>Powered By</p>
          <aside className="facilitators-logo-containers">
            <img src={genesysLogo} alt="" />
            <img src={teneceLogo} alt="" />
          </aside>
        </section>
      </main>
      <footer className="bzo-landing-footer bzo-container">
        <img src={bezaoLogo} alt="" />
      </footer>
    </div>
  );
}

export { LandingPage }
